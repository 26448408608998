<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Widgets
          </v-card-title>
          <v-card-text>
            <p>
              You can include our widgets in your website to allow pilots to PPR
              and Book Out without having to code any special forms. We do all
              the complex handing and notify you of new requests immediately.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-icon class="pe-2">
              mdi-code-tags
            </v-icon>
            PPR Widget
          </v-card-title>
          <v-card-text>
            <p>
              To include our PPR widget in your website follow these
              instructions, or send them to the person responsible for your
              website.
            </p>
            <ol class="mb-4">
              <li>
                Copy the entire tag below, or tap the Copy button.
              </li>
              <li>
                Paste the tag into the exact spot in your website HTML where you
                would like the widget to appear.
              </li>
            </ol>
            <code-block>{{ arrivalWidgetCode }}</code-block>
          </v-card-text>
          <v-card-actions>
            <v-dialog
              v-model="arrivalPreviewOpen"
              light
              scrollable
              max-width="960px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-eye
                  </v-icon>
                  Preview
                </v-btn>
              </template>
              <v-card>
                <v-card-title>PPR Widget</v-card-title>
                <v-divider />
                <v-card-text class="pa-3">
                  <div ref="arrivalWidget" />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    @click="arrivalPreviewOpen = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-spacer />

            <v-btn
              color="primary"
              text
              v-clipboard:copy="arrivalWidgetCode"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <v-icon>mdi-content-copy</v-icon>
              Copy
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-icon class="pe-2">
              mdi-code-tags
            </v-icon>
            Booking Out Widget
          </v-card-title>
          <v-card-text>
            <p>
              To include our Booking Out widget in your website follow these
              instructions, or send them to the person responsible for your
              website.
            </p>
            <ol class="mb-4">
              <li>
                Copy the entire tag below, or tap the Copy button.
              </li>
              <li>
                Paste the tag into the exact spot in your website HTML where you
                would like the widget to appear.
              </li>
            </ol>
            <code-block>{{ departureWidgetCode }}</code-block>
          </v-card-text>
          <v-card-actions>
            <v-dialog
              v-model="departurePreviewOpen"
              light
              scrollable
              max-width="960px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-eye
                  </v-icon>
                  Preview
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Book Out Widget</v-card-title>
                <v-divider />
                <v-card-text class="pa-3">
                  <div ref="departureWidget" />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    @click="departurePreviewOpen = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-spacer />

            <v-btn
              color="primary"
              text
              v-clipboard:copy="departureWidgetCode"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <v-icon>mdi-content-copy</v-icon>
              Copy
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-icon class="pe-2">
              mdi-code-tags
            </v-icon>
            WordPress Plugin
          </v-card-title>
          <v-card-text>
            <p>
              To make it even easier to use on your WordPress website - we have created a custom plugin to enable you to integrate with us within minutes!
            </p>
            <ol class="mb-4">
              <li>
                Login to your WordPress administration panel
              </li>
              <li>
                Click Plugins (normally in the left hand menu)
              </li>
              <li>
                At the top left of the Plugins page, click "Add New"
              </li>
              <li>
                On the top right of the page, search for: "AirfieldHub"
              </li>
              <li>
                Click "Install", this may take a few seconds
              </li>
              <li>
                Click "Activate"
              </li>
              <li>
                You will now see a new menu option at the bottom of the left hand menu called AirfieldHub
              </li>
              <li>
                Click "AirfieldHub" where you will be presented with a field to enter your unique AirfieldHub key given below these instructions.
              </li>
              <li>
                Copy the AirfieldHub key given here, into the field, and press Save.
              </li>
              <li>
                Now navigate to edit the page, post, or other content where you would like the PPR and BOOKOUT forms to appear on your website. 
              </li>
              <li>
                Usually on the top left of the edit page / post screen, you should see an option with a + sign, click it to open the Blocks panel, search for AirfieldHub.
              </li>
              <li>
                You can now drag and drop the forms wherever you like in your content, and you should see what your form will look like immediately.
              </li>
              <li>
                Once happy, press the "UPDATE" button on the top right of the page, and go visit that page to check how your page looks.
              </li>
              <li>
                For more information, please visit: <a
                  href="https://wordpress.org/plugins/airfieldhub/"
                  target="_blank"
                >https://wordpress.org/plugins/airfieldhub/</a>
              </li>
            </ol>
            <code-block>{{ airfieldhub_key }}</code-block>
          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn
              color="primary"
              text
              v-clipboard:copy="airfieldhub_key"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <v-icon>mdi-content-copy</v-icon>
              Copy
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-icon class="pe-2">
              mdi-code-tags
            </v-icon>
            ReactJS Library
          </v-card-title>
          <v-card-text>
            <p>
              To make it even easier to use in your ReactJS websites and apps, we have published and maintain a library called react-airfieldhub. This can be installed from within your project with YARN or NPM.
            </p>
            <ol class="mb-4">
              <li>
                For full details on how to use it, please view our NPM readme: <a
                  href="https://www.npmjs.com/package/react-airfieldhub"
                  target="_blank"
                >https://www.npmjs.com/package/react-airfieldhub</a>
              </li>
              <li>
                Copy your AirfieldHub key given below:
              </li>
            </ol>
            <code-block>{{ airfieldhub_key }}</code-block>
          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn
              color="primary"
              text
              v-clipboard:copy="airfieldhub_key"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <v-icon>mdi-content-copy</v-icon>
              Copy
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-icon class="pe-2">
              mdi-code-tags
            </v-icon>
            Angular 14+ Library
          </v-card-title>
          <v-card-text>
            <p>
              To make it even easier to use in your Angular websites and apps, we have published and maintain a library called ngx-airfieldhub. This can be installed from within your project with YARN or NPM.
            </p>
            <ol class="mb-4">
              <li>
                For full details on how to use it, please view our NPM readme: <a
                  href="https://www.npmjs.com/package/ngx-airfieldhub"
                  target="_blank"
                >https://www.npmjs.com/package/ngx-airfieldhub</a>
              </li>
              <li>
                Copy your AirfieldHub key given below:
              </li>
            </ol>
            <code-block>{{ airfieldhub_key }}</code-block>
          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn
              color="primary"
              text
              v-clipboard:copy="airfieldhub_key"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <v-icon>mdi-content-copy</v-icon>
              Copy
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import base64url from "base64url";
import CodeBlock from "../../../components/CodeBlock.vue";

Vue.use(VueClipboard);

const copiedSuccess = "Copied widget code to your clipboard.";
const copiedError =
  "There was a problem copying the widget code, please select and copy manually.";

export default {
  components: { CodeBlock },

  data: () => ({
    snackbar: false,
    snackbarText: copiedSuccess,
    arrivalPreviewOpen: false,
    departurePreviewOpen: false
  }),

  computed: {
    arrivalWidgetSrc() {
      return "https://airfieldhub.com/widgetArrival.umd.min.js";
    },
    arrivalWidgetUri() {
      const jsonPayload = JSON.stringify({
        airfieldId: this.$store.getters["auth/airfieldId"],
        pilotBriefingUrl: this.$store.getters["auth/airfieldPilotBriefingUrl"]
      });
      const payload = base64url.encode(jsonPayload);
      return `https://airfieldhub.com/widget.html/arrival/${payload}/`;
    },
    arrivalWidgetCode() {
      const src = this.arrivalWidgetSrc;
      const uri = this.arrivalWidgetUri;
      return (
        `<script src="${src}" data-uri="${uri}" id="afhw-arrival" async><` +
        "/script>"
      ).trim();
    },

    departureWidgetSrc() {
      return "https://airfieldhub.com/widgetDeparture.umd.min.js";
    },
    departureWidgetUri() {
      const jsonPayload = JSON.stringify({
        airfieldId: this.$store.getters["auth/airfieldId"],
        pilotBriefingUrl: this.$store.getters["auth/airfieldPilotBriefingUrl"]
      });
      const payload = base64url.encode(jsonPayload);
      return `https://airfieldhub.com/widget.html/departure/${payload}/`;
    },
    departureWidgetCode() {
      const src = this.departureWidgetSrc;
      const uri = this.departureWidgetUri;
      return (
        `<script src="${src}" data-uri="${uri}" id="afhw-departure" async><` +
        "/script>"
      ).trim();
    },
    airfieldhub_key(){
      const jsonPayload = JSON.stringify({
        airfieldId: this.$store.getters["auth/airfieldId"],
        pilotBriefingUrl: this.$store.getters["auth/airfieldPilotBriefingUrl"]
      });
      const payload = base64url.encode(jsonPayload);
      return payload;
    }
  },

  methods: {
    onCopy() {
      this.snackbarText = copiedSuccess;
      this.snackbar = true;
    },
    onError(e) {
      console.log(e);
      this.snackbarText = copiedError;
      this.snackbar = true;
    }
  },

  watch: {
    arrivalPreviewOpen(visible) {
      if (visible) {
        setTimeout(
          function() {
            const arrivalWidgetScriptTag = document.createElement("script");
            arrivalWidgetScriptTag.setAttribute("src", this.arrivalWidgetSrc);
            arrivalWidgetScriptTag.setAttribute(
              "data-uri",
              this.arrivalWidgetUri
            );
            arrivalWidgetScriptTag.setAttribute("id", "afhw-arrival");
            arrivalWidgetScriptTag.setAttribute("async", true);
            this.$refs.arrivalWidget.appendChild(arrivalWidgetScriptTag);
          }.bind(this),
          100
        );
      } else {
        this.$refs.arrivalWidget.innerHTML = "";
      }
    },
    departurePreviewOpen(visible) {
      if (visible) {
        setTimeout(
          function() {
            const departureWidgetScriptTag = document.createElement("script");
            departureWidgetScriptTag.setAttribute(
              "src",
              this.departureWidgetSrc
            );
            departureWidgetScriptTag.setAttribute(
              "data-uri",
              this.departureWidgetUri
            );
            departureWidgetScriptTag.setAttribute("id", "afhw-departure");
            departureWidgetScriptTag.setAttribute("async", true);
            this.$refs.departureWidget.appendChild(departureWidgetScriptTag);
          }.bind(this),
          100
        );
      } else {
        this.$refs.departureWidget.innerHTML = "";
      }
    }
  }
};
</script>
