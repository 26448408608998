<template>
  <pre
    class="pa-4 rounded code"
    wrap
    style="word-break: break-all"
  ><slot /></pre>
</template>

<script>
export default {
  name: "CodeBlock"
};
</script>

<style scoped>
.theme--dark pre.code {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme--light pre.code {
  background-color: rgba(0, 0, 0, 0.08);
}
</style>
